import { BaseSchema, ModelType, ToManyRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoicePaymentReminderGroupRelationships = {
    invoicingPaymentReminderSettings: ToManyRelationship<ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS>;
};

type InvoicePaymentReminderGroupAttributes = {
    title: string;
    default: boolean;
};

type InvoicePaymentReminderGroupMeta = {};

export default class InvoicePaymentReminderGroupSchema extends BaseSchema<InvoicePaymentReminderGroupAttributes, InvoicePaymentReminderGroupMeta, InvoicePaymentReminderGroupRelationships> {
    protected type: ModelType = ModelType.INVOICE_PAYMENT_REMINDER_GROUPS;
}
