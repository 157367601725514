<script setup lang="ts">
    import VatRateCollection from '../../../app/invoicing/collections/VatRateCollection';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ContactSchema from '~~/app/contact/schemas/ContactSchema';
    import { useEvent } from '@/composables/useEventBus';
    import ContactEndpoint from '~~/app/contact/endpoints/ContactEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Contact from '~~/app/contact/models/Contact';
    import { ValidatedVatNumberResponse } from '~~/app/common/endpoints/ValidateVatNumberEndpoint';
    import { ValidatedSiretResponse } from '@/app/common/endpoints/ValidateSiretEndpoint';
    import InvoicePaymentReminderGroup from '~/app/invoicing/models/InvoicePaymentReminderGroup';
    import InvoicePaymentReminderGroupCollection from '~/app/invoicing/collections/InvoicePaymentReminderGroupCollection';

    type ModalPayload = FormModalPayload<Contact, Fields> & {
        openFromInvoiceForm: boolean;
    };
    interface Fields {
        vatNumber: string;
        lastName: string;
        firstName: string;
        companyName: string;
        contactType: string;
        email: string | null;
        phone: string | null;
        address: Address | null;
        billingAddress: Address | null;
        isProfessional: boolean;
        locale: string;
        vatRate: string;
        honorificPrefix: string;
        groupId: string;
    }
    const translation = useTranslation();
    const form = useForm<Fields>({
        vatNumber: '',
        siret: '',
        lastName: '',
        firstName: '',
        companyName: '',
        email: '',
        phone: '',
        address: null,
        billingAddress: null,
        contactType: 'client',
        isProfessional: false,
        locale: translation?.getCurrentLocale() || useGetDefaultLocale(),
        vatRate: '',
        honorificPrefix: '',
        groupId: '',
    });

    const displayBillingAddress = ref(false);
    const displayAsProfessional = ref<boolean>(true);
    const isOpenFromInvoiceForm = ref<boolean>(false);

    const { loading: loadingGroups, collection, load } = useCollectionLoader<InvoicePaymentReminderGroup, InvoicePaymentReminderGroupCollection>(ModelType.INVOICE_PAYMENT_REMINDER_GROUPS);
    const { modalName, model } = useModelFormModal<Contact, Fields>(
        Contact,
        async (payload?: ModalPayload) => {
            loading.value = true;
            load();
            if (payload?.openFromInvoiceForm) isOpenFromInvoiceForm.value = payload?.openFromInvoiceForm;
            await fetchVatRates();
            await fillForm(payload);
            loading.value = false;
        },
        () => {
            isOpenFromInvoiceForm.value = false;
            form.reset();
        }
    );
    const loading = ref<boolean>(false);
    const fillForm = async (payload?: FormModalPayload<Contact, Fields>) => {
        form.reset();
        form.set('locale', translation?.getCurrentLocale() || useGetDefaultLocale());
        if (payload?.fields && payload?.fields.contactType) {
            form.set('contactType', payload?.fields.contactType);
        }

        await fillWithCompleteModel(payload);

        if (form.get('billingAddress')) {
            displayBillingAddress.value = true;
        }
    };

    const fillWithCompleteModel = async (payload?: FormModalPayload<Contact, Fields>): Promise<void> => {
        if (!payload || !payload.model) return;

        const endpoint = EndpointFactory.make(ModelType.CONTACTS);
        const response = await endpoint.addInclude('invoices,defaultVatRate,invoicePaymentReminderGroup').retrieve(payload.model.getId());
        if (response.error || !response.data) {
            useToasteoError();
            return;
        }

        const contact = response.data;
        form.fillWithModel(contact);
        model.value = contact;

        // form.set('vatRate', contact.defaultVatRate?.getId);
        // console.log('has contact', contact, form);
        form.set('groupId', contact.invoicePaymentReminderGroup?.getId() || getDefaultGroup());
        form.set('vatRate', contact.defaultVatRate?.getId() || '');
    };

    const getDefaultGroup = () => {
        return collection.value?.filter((group: InvoicePaymentReminderGroup) => group.title === 'default')[0].getId() ?? '';
    };

    const vatRates = ref<VatRateCollection | null | undefined>();
    const fetchVatRates = async () => {
        const endpoint = EndpointFactory.make(ModelType.VAT_RATES);
        const response = await endpoint.index();
        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }
        vatRates.value = response.data;
    };

    const prefillFormWithVATNumber = (response: ValidatedVatNumberResponse) => {
        if (!form.get('companyName') && response.data?.company) {
            form.set('companyName', response.data.company);
        }
        if (!form.get('address') && response.data?.address) {
            form.set('address', response.data.address.display);
        }
    };

    const prefillFormWithSiret = (response: ValidatedSiretResponse) => {
        if (!form.get('companyName') && response.data?.company) {
            form.set('companyName', response.data.company);
        }
        if (!form.get('address') && response.data?.address) {
            form.set('address', response.data.address.display);
        }
    };

    const submit = async () => {
        const data = form.dataExcept(['vatRate', 'groupId']);
        delete data.isProfessional;
        const schema = ContactSchema.make({ attributes: data });
        if (!displayBillingAddress.value) {
            schema.attributes!.billingAddress = null;
        }

        if (form.get('isProfessional') !== true) {
            schema.attributes!.companyName = null;
            schema.attributes!.vatNumber = null;
            schema.attributes!.siret = null;
        }

        if (form.get('vatRate')) {
            schema.addToOneRelationship('defaultVatRate', ModelType.VAT_RATES, form.get('vatRate'));
        }
        if (form.get('groupId')) {
            schema.addToOneRelationship('invoicePaymentReminderGroup', ModelType.INVOICE_PAYMENT_REMINDER_GROUPS, form.get('groupId'));
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: ContactEndpoint = EndpointFactory.make(ModelType.CONTACTS) as ContactEndpoint;
        const response = await form.loadUntil(endpoint.addInclude('worksites,invoices').storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="Contact"
        scrollable
        @before-close="form.reset()"
    >
        <ModalLoader v-if="loading || loadingGroups"></ModalLoader>
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ model ? $t(`contact.${form.get('contactType')}.edit_form.title`) : $t(`contact.${form.get('contactType')}.create_form.title`) }}
                </h2>
            </div>

            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <AlertBox v-if="model">
                        <div>
                            <p>{{ $t('contact.form.edit_warning.text') }}</p>
                            <p
                                v-if="isOpenFromInvoiceForm"
                                class="mt-1"
                            >
                                {{ $t('contact.form.edit_warning.open_from_invoice_form') }}
                            </p>

                            <div
                                v-if="model?.getLatestInvoices().length > 0"
                                class="mt-4"
                            >
                                <p class="mb-1 font-semibold">{{ $t('contact.form.edit_warning.invoice_list') }}</p>
                                <ul class="pl-6 list-disc">
                                    <li
                                        v-for="invoice in model?.getLatestInvoices()"
                                        :key="invoice.getInternalId()"
                                        class="space-x-1"
                                    >
                                        <span>{{ $t(`invoice_document_type.${invoice.documentType}`) }}</span>
                                        <span> {{ invoice.numberOrDraft }}</span>
                                        <span>({{ invoice.trueDate.toEUDateString() }})</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </AlertBox>

                    <ContactContactFormModalExistsAlert
                        v-if="displayAsProfessional"
                        :id="model?.getId()"
                        :vat-number="form.get('vatNumber')"
                        :contact-type="form.get('contactType')"
                        :email="form.get('email')"
                    />
                    <FormTwoFields>
                        <template #field-1>
                            <FormCheckbox
                                :form="form"
                                input-name="isProfessional"
                            />
                        </template>
                        <template #field-2>
                            <LazyFormPaymentReminderGroupsSelect
                                :groups="collection"
                                :form="form"
                                input-name="groupId"
                            />
                        </template>
                    </FormTwoFields>

                    <template v-if="form.get('isProfessional')">
                        <FormTwoFields v-if="form.get('isProfessional')">
                            <template #field-1>
                                <FormVatNumber
                                    :form="form"
                                    input-name="vatNumber"
                                    autofocus
                                    @valid-vat-number="prefillFormWithVATNumber"
                                />
                            </template>
                            <template #field-2>
                                <FormSiret
                                    :form="form"
                                    input-name="siret"
                                    @valid-siret="prefillFormWithSiret"
                                />
                            </template>
                        </FormTwoFields>

                        <FormInput
                            :form="form"
                            input-name="companyName"
                        />
                    </template>

                    <FormTwoFields>
                        <template #field-1>
                            <FormInput
                                :form="form"
                                input-name="firstName"
                            />
                        </template>
                        <template #field-2>
                            <FormInput
                                :form="form"
                                input-name="lastName"
                            />
                        </template>
                    </FormTwoFields>
                    <FormTwoFields>
                        <template #field-1>
                            <FormPrefixInput
                                :form="form"
                                input-name="honorificPrefix"
                            />
                        </template>
                    </FormTwoFields>
                    <FormTwoFields>
                        <template #field-1>
                            <FormEmailInput
                                :form="form"
                                input-name="email"
                            />
                        </template>
                        <template #field-2>
                            <FormPhoneInput
                                :key="form.get('locale')"
                                :form="form"
                                input-name="phone"
                            />
                        </template>
                    </FormTwoFields>

                    <FormAddressAutocomplete
                        :form="form"
                        input-name="address"
                        element-class-name=" "
                    />
                    <span
                        class="block mt-[10px] mb-6 text-xs text-gray-400 transition-all cursor-pointer hover:text-primary hover:underline"
                        @click.prevent="displayBillingAddress = !displayBillingAddress"
                    >
                        {{ displayBillingAddress ? $t('contact.contact_form_modal.hide_billing_address_field') : $t('contact.contact_form_modal.display_billing_address_field') }}
                    </span>

                    <template v-if="displayBillingAddress">
                        <FormAddressAutocomplete
                            :form="form"
                            input-name="billingAddress"
                            :tooltip="$t('contact.billing_address_tooltip')"
                        />
                    </template>
                    <FormTwoFields>
                        <template #field-1>
                            <FormLocaleSelect
                                :form="form"
                                input-name="locale"
                            />
                        </template>
                        <template #field-2>
                            <FormSelect
                                :form="form"
                                with-null-option
                                :label="$t('form.labels.vatRate')"
                                input-name="vatRate"
                            >
                                <option
                                    v-for="vatRate in vatRates"
                                    :key="vatRate.getId()"
                                    :value="vatRate.getId()"
                                >
                                    {{ vatRate.title }}
                                </option>
                            </FormSelect>
                        </template>
                    </FormTwoFields>
                </div>

                <div :class="$theme('modal.footer.container')">
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.save') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </div>
    </ModelFormModal>
</template>
