<script setup lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import ImportedFileEndpoint from '../../../app/common/endpoints/ImportedFileEndpoint';
    import '@vuepic/vue-datepicker/dist/main.css';

    useModal('invoicing:expenses-export', (payload: any) => {
        resetDate();
    });
    const documentType = ref<string>('expenses');
    const loading = ref<boolean>(false);
    const createdBefore = ref(moment());
    const createdAfter = ref(moment().subtract(1, 'months'));

    const resetDate = () => {
        createdBefore.value = moment();
        createdAfter.value = moment().subtract(1, 'months');
    };

    const dateRange = computed({
        get() {
            return [createdBefore.value, createdAfter.value];
        },
        set(value: Array<Date>) {
            createdBefore.value = moment(value[1]);
            createdAfter.value = moment(value[0]);
        },
    });
    const endpoint = new ImportedFileEndpoint();

    const submit = async () => {
        loading.value = true;

        loading.value = true;
        const response = await endpoint.export({
            documentType: documentType.value,
            createdBefore: createdBefore.value.format('YYYY-MM-DD'),
            createdAfter: createdAfter.value.format('YYYY-MM-DD'),
        });
        loading.value = false;
        if (response?.error) return useToasteoError();
        if (response.response?.status === 204) return useToasteoError('page.empty_content.title');
        useEvent('common:files:export-modal:open');
        useToasteoSuccess('toasts.export_generated');
        useEvent('invoicing:expenses-export:close');
    };
</script>

<template>
    <Modal name="invoicing:expenses-export">
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ $t('actions.export') }}
            </h2>
        </div>
        <form
            class="space-y-4"
            :class="$theme('modal.padding')"
            @submit.prevent="submit"
        >
            <div class="flex flex-row-reverse items-center w-full">
                <div class="p-4">
                    <div class="p-4 mb-4 text-sm text-center text-blue-900 bg-blue-200 rounded">
                        <span>{{ createdAfter?.format('DD/MM/YYYY') }} - {{ createdBefore?.format('DD/MM/YYYY') }}</span>
                    </div>
                    <VueDatePicker
                        v-model="dateRange"
                        inline
                        auto-apply
                        range
                        :locale="useGetCurrentMomentLocale()"
                        :partial-range="false"
                        :enable-time-picker="false"
                        multi-calendars
                        :multi-static="false"
                    />
                </div>
            </div>
            <div :class="$theme('modal.footer.button_container')">
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.cancel')"
                    prevent-default
                    @clicked="useEvent('invoicing:expenses-export:close')"
                >
                    {{ $t('actions.cancel') }}
                </LoadingButton>
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :loading="loading"
                    type="submit"
                >
                    <i class="mr-2 fa-light fa-export"></i>
                    {{ $t('actions.export') }}
                </LoadingButton>
            </div>
        </form>
    </Modal>
</template>
