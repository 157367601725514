<script setup lang="ts">
    import { onClickOutside } from '@vueuse/core';
    import { v4 as uuidv4 } from 'uuid';
    import { z } from 'zod';
    import EmailBadgeIcon from './EmailBadgeIcon.vue';
    import { isEmail } from '~/utils/string-helpers';

    const emit = defineEmits(["changed"]);

    const props = defineProps({
  value: null,
  placeholder: null,
  multiple: { type: Boolean, default: false }
});

    const loading = ref(false);
    const { search } = useMeilisearch();
    export type meilisearchEmailBadgeType = {
        company_name: string;
        contact_type: string;
        display_name?: string;
        email: string;
        first_name: string;
        last_name: string;
        phone: string;
        siret: string;
        tenant_id: string;
        uuid: string;
        vat_number: string;
    };
    const meilisearchContacts = ref<meilisearchEmailBadgeType>();

    const searchContacts = async () => {
        loading.value = true;

        const results = await search({
            model: 'contacts',
            query: currentInput.value,
            limit: 5,
        });
        meilisearchContacts.value = results.hits;
        loading.value = false;
        if (z.string().email().safeParse(currentInput.value).success) {
            meilisearchContacts.value?.push({
                email: currentInput.value,
            });
        }
    };

    const input: Ref<HTMLElement | null> = ref(null);
    const email = ref(props.value);
    const emails = ref<string | string[]>();
    const editing = ref(false);
    const currentInput = ref('');

    onClickOutside(input, (event) => (editing.value = false));

    const isArray = (emails: string | string[]) => {
        return Array.isArray(emails);
    };

    const processEmails = (input: string) => {
        return input
            .split(',')
            .map((email) => email.trim())
            .filter((trimmedEmail) => trimmedEmail !== '');
    };

    const emailBadges = computed(() =>
        processEmails(email.value).map((validEmail) => ({
            id: uuidv4(),
            email: validEmail,
        }))
    );

    const edit = () => {
        editing.value = true;
        nextTick(() => {
            input.value!.focus();
        });
    };

    const remove = (uuid: string) => {
        const updatedEmails = emailBadges.value.filter((emailBadge) => emailBadge.id !== uuid).map((emailBadge) => emailBadge.email);
        email.value = updatedEmails.join(',');
        emit('changed', email.value);
    };

    const validEmail = (emails: string | string[]) => {
        if (!props.multiple) return isEmail(currentInput.value);
        if (!isArray(emails)) return;
        return emails.every((email) => isEmail(email));
    };

    const ensureUniqueEmail = (newEmails: string[]) => {
        const uniqueNewEmails = newEmails.filter((newEmail) => !email.value.includes(newEmail));
        if (!uniqueNewEmails.length) return resetInput();
        return uniqueNewEmails;
    };

    const confirmEmails = () => {
        emails.value = props.multiple ? processEmails(currentInput.value) : currentInput.value.trim();

        if (!validEmail(emails.value)) return resetInput();

        if (!isArray(emails.value)) return;

        const newEmails = ensureUniqueEmail(emails.value);

        if (!newEmails) return;

        if (!props.multiple) {
            email.value = newEmails[0]; // Handle single email
        } else {
            email.value = email.value ? `${email.value},${newEmails.join(',')}` : newEmails.join(',');
        }

        editing.value = false;
        emit('changed', email.value);
        resetInput();
    };

    const resetInput = () => {
        currentInput.value = '';
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key !== 'Enter' && event.key !== ',') return;
        confirmEmails(); // Confirm emails when Enter or a comma is pressed
    };

    const select = (meilisearchContact: any) => {
        currentInput.value = meilisearchContact.email;
        confirmEmails();
        editing.value = false;
        resetInput();
    };

    onMounted(() => {
        searchContacts();
    });
</script>

<template>
    <div
        class="relative"
        :class="emailBadges.length > 1 ? 'flex flex-col space-y-2' : 'flex items-center space-x-2'"
    >
        <div class="flex flex-wrap items-center gap-2">
            <FormEmailBadgeItem
                v-for="badge in emailBadges"
                :key="badge.id"
                :badge="badge"
                @remove="remove"
            />
        </div>
        <button
            v-if="!editing"
            class="border border-gray-200 py-1 px-2 rounded-md text-sm text-gray-400 w-fit items-center hover:bg-green-500 hover:text-white"
            @click.prevent="edit"
        >
            <i class="fa-solid fa-circle-plus pr-1"></i> {{ $t('actions.add') }}
        </button>
        <input
            v-if="editing"
            ref="input"
            v-model="currentInput"
            class="w-full p-2 text-sm text-red-600 outline-none focus:text-black"
            type="text"
            @input.prevent.stop="searchContacts()"
            @keydown="handleKeyDown"
            @blur="confirmEmails"
        />

        <div v-if="editing || currentInput !== ''">
            <div class="flex flex-col absolute left-0 top-full bg-white rounded-md shadow-md z-50 w-full min-w-[400px]">
                <div
                    v-for="meilisearchContact in meilisearchContacts"
                    :key="meilisearchContact?.uuid"
                    :class="[$theme('contextmenu.link', 'cursor-pointer')]"
                >
                    <div
                        class="flex space-x-2 items-center"
                        @click.prevent="select(meilisearchContact)"
                    >
                        <EmailBadgeIcon :meilisearch-contact="meilisearchContact" />

                        <div class="space-y-1">
                            <span
                                v-if="meilisearchContact?.display_name"
                                class="text-sm block capitalize font-medium"
                                >{{ `${meilisearchContact.display_name}` }}</span
                            >
                            <span class="text-sm block">{{ `${meilisearchContact?.email}` }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
