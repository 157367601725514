<script setup lang="ts">
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~/app/invoicing/endpoints/InvoiceEndpoint';
    import InvoiceSchema from '~/app/invoicing/schemas/InvoiceSchema';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
        dueDays: number;
    }
    const invoice = reactiveModel<Invoice>(null);
    const form = useForm<{
        dueAt: moment.Moment;
    }>({
        dueAt: moment().toDateString(),
    });

    useModal(
        'invoicing:invoice:due-date',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            form.set('dueAt', moment(form.get('dueAt')).add(payload.dueDays, 'days').toDateString());
        },
        () => {
            form.reset();
        }
    );

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;

    const submit = async () => {
        const schema = new InvoiceSchema({ id: invoice.value?.getId(), attributes: { dueAt: form.get('dueAt') } });
        const response = await form.loadUntil(endpoint.update(schema));
        if (response.data) {
            useToasteoSuccess();
        }
        useEvent('invoicing:invoice:due-date:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:due-date"
    >
        <template #default>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('invoicing.invoice_due_date_modal.title') }}
                </h2>
                <!-- <h3 :class="$theme('modal.title.subtext')">
                        {{ $t('invoicing.invoice_due_date_modal.subtext') }}
                    </h3> -->
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <FormDate
                        :form="form"
                        input-name="dueAt"
                    />
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice:due-date:close')"
                        >
                            {{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton
                            :class-name="$theme('button.style.default')"
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                        >
                            {{ $t('actions.confirm') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
