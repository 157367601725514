<script setup lang="ts">
    import InvoicingPaymentRemindersSetting from '@/app/invoicing/models/InvoicingPaymentRemindersSetting';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoicingPaymentRemindersSettingSchema from '~~/app/invoicing/schemas/InvoicingPaymentRemindersSettingSchema';
    import { useEvent } from '@/composables/useEventBus';
    import InvoicingPaymentRemindersSettingEndpoint from '~~/app/invoicing/endpoints/InvoicingPaymentRemindersSettingEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import InvoicePaymentReminderGroup from '~/app/invoicing/models/InvoicePaymentReminderGroup';
    import InvoicePaymentReminderGroupSchema from '~/app/invoicing/schemas/InvoicePaymentReminderGroupSchema';

    interface Fields {
        title: string;
        default: boolean;
    }
    const form = useForm<Fields>({
        title: '',
        default: false,
    });

    const { modalName, model } = useModelFormModal<InvoicePaymentReminderGroup, Fields>(
        InvoicePaymentReminderGroup,
        (payload?: FormModalPayload<InvoicingPaymentRemindersSetting, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<InvoicePaymentReminderGroup, Fields>) => {
        if (payload?.model) {
            form.fillWithModel(payload.model);
        }
        if (payload?.fields) {
            form.fill(payload.fields);
        }
    };
    const submit = async () => {
        let schema = InvoicePaymentReminderGroupSchema.make({ attributes: form.data() });

        if (model.value) {
            schema.id = model.value?.getId();
        }

        let endpoint = EndpointFactory.make(ModelType.INVOICE_PAYMENT_REMINDER_GROUPS);
        let response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoicePaymentReminderGroup"
        scrollable
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('invoicing.invoicing_payment_reminders_settings.edit_form.title') : $t('invoicing.invoicing_payment_reminders_settings.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <div class="flex items-center space-x-4">
                    <div class="w-full">
                        <FormInput
                            :form="form"
                            input-name="title"
                            input-type="text"
                            autofocus
                            :label="$t('form.labels.title')"
                        />
                    </div>
                    <!-- <div class="pt-5">
                        <FormCheckbox
                            :form="form"
                            input-name="enabled"
                        />
                    </div> -->
                </div>

                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
