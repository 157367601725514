<template>
    <div class="text-sm bg-center bg-cover rounded-full cursor-pointer bg-neutral-200 w-8 h-8 lg:h-10 lg:w-10 text-center items-center flex justify-center">
        <i
            class="fa-solid text-lg"
            :class="getIcon()"
        ></i>
    </div>
</template>

<script lang="ts" setup>
    import { meilisearchEmailBadgeType } from './EmailBadge.vue';
    import { ContactType } from '~/app/contact/enums/ContactType';

    const props = defineProps({
  meilisearchContact: null
});

    const getIcon = () => {
        switch (props.meilisearchContact.contact_type) {
            case ContactType.SUPPLIER:
                return 'fa-building-memo';
            case ContactType.CLIENT:
                return ' fa-user-check';
            default:
                return 'fa-user';
        }
    };
</script>

<style></style>
