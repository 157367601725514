<template>
    <p>{{ t('inventory.product.import_form.content.info') }}</p>
    <div class="flex justify-between space-x-2 whitespace-nowrap px-3 py-2 mb-4 text-xs font-semibold text-black border border-gray-200">
        <span>
            {{ $t('contact.contact.import_form.content.file.first_name') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.last_name') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.company_name') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.honorific_prefix') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.email') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.phone') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.addresse') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.addresse_line_2') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.postcode') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.city') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.country') }}
        </span>
    </div>
    <div class="flex justify-between space-x-2 whitespace-nowrap px-3 py-2 mb-4 text-xs font-semibold text-black border border-gray-200">
        <span class="overflow-ellipsis">
            {{ $t('contact.contact.import_form.content.file.billing_addresse') }}
        </span>
        <span class="overflow-ellipsis">
            {{ $t('contact.contact.import_form.content.file.billing_addresse_line_2') }}
        </span>
        <span class="overflow-ellipsis">
            {{ $t('contact.contact.import_form.content.file.billing_postcode') }}
        </span>
        <span class="overflow-ellipsis">
            {{ $t('contact.contact.import_form.content.file.billing_city') }}
        </span>
        <span class="overflow-ellipsis">
            {{ $t('contact.contact.import_form.content.file.billing_country') }}
        </span>
        <span>
            {{ $t('contact.contact.import_form.content.file.vat_number') }}
        </span>
    </div>
    <div class="space-y-4 text-xs font-normal text-center">
        <p>{{ t('inventory.product.import_form.content.file.info') }}</p>
        <div class="text-lg text-blue-600">
            <a :href="$t('contact.import_form.example_download_url')">
                <i class="fa-regular fa-file-csv"></i>
                {{ t('inventory.product.import_form.content.file.example.download') }}</a
            >
        </div>
    </div>
    <div :class="$theme('modal.footer.container')">
        <div :class="$theme('modal.footer.button_container')">
            <LoadingButton
                :extra-class-name="$theme('modal.footer.button')"
                :class-name="$theme('button.style.cancel')"
                prevent-default
                @clicked="$emit('close')"
                >{{ t('actions.cancel') }}</LoadingButton
            >
            <LoadingButton
                :extra-class-name="$theme('modal.footer.button')"
                @click="$emit('continue')"
                >{{ t('actions.continue') }}</LoadingButton
            >
        </div>
    </div>
</template>
<script lang="ts" setup>
    defineEmits(["close", "continue"]);

    const { t } = useTranslation();
</script>
