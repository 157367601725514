<script setup lang="ts">
    import InvoicePaymentReminderGroupEndpoint from '~/app/invoicing/endpoints/InvoicePaymentReminderGroupEndpoint';
    import InvoicePaymentReminderGroup from '~/app/invoicing/models/InvoicePaymentReminderGroup';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';

    const endpoint = EndpointFactory.make(ModelType.INVOICE_PAYMENT_REMINDER_GROUPS) as InvoicePaymentReminderGroupEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();
    const deleteButton = ref(null);

    const destroy = async (model: InvoicePaymentReminderGroup) => {
        await deleteButton.value.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="InvoicePaymentReminderGroup"
        :menu-height="100"
    >
        <div v-if="!slotProps.model.default">
            <a
                href="#"
                :class="$theme('contextmenu.link')"
                @click.prevent="onAction(() => slotProps.model?.edit())"
            >
                <i
                    class="fa-pencil"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>{{ $t('actions.edit') }}</span>
            </a>
            <LoadingButton
                ref="deleteButton"
                :class-name="$theme('contextmenu.link')"
                @click.prevent="destroy(slotProps.model)"
            >
                <i
                    class="fa-trash"
                    :class="$theme('contextmenu.icon')"
                ></i>
                <span>{{ $t('actions.delete') }}</span>
            </LoadingButton>
        </div>
        <div v-else>{{ contextMenu.close() }}</div>
    </ModelContextMenu>
</template>
