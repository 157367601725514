<template>
    <div class="max-w-full overflow-x-auto nice-scrollbar">
        <table class="w-full max-w-full overflow-x-auto border-t border-l border-gray-200 nice-scrollbar">
            <thead>
                <tr class="border-b border-r border-gray-200 whitespace-nowrap">
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.first_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.last_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.company_name') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.honorific_prefix') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.email') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.phone') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.addresse') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.addresse_line_2') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.postcode') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.city') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_country') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_addresse') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_addresse_line_2') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_postcode') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_city') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.billing_country') }}
                    </th>
                    <th class="p-2 text-xs text-left">
                        {{ $t('contact.contact.import_form.content.file.vat_number') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(client, index) in props.clients"
                    :key="index"
                    class="border-b border-r border-gray-200"
                >
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.first_name ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.last_name ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.company_name ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.honorific_prefix ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.email ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.phone_number ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.address_line_1 ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.address_line_2 ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.postal_code ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.city ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.country ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.billing_address_line_1 ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.billing_address_line_2 ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.billing_postal_code ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.billing_city ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.billing_country ?? '-' }}
                    </td>
                    <td class="p-2 text-xs text-left text-gray-700">
                        {{ client.vat_number ?? '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" setup>
    import { RawPreviewClient } from '~/app/common/classes/ClientPreview';

    const props = defineProps({
  clients: null
});
</script>
